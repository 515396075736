/* latin */
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap");
/* 日本語 */
@import url("https://fonts.googleapis.com/css2?family=M+PLUS+1p:wght@300&display=swap");
/* русский */
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;1,300&display=swap");
p,
a,
button {
  font-family: "Quicksand", "M PLUS 1p", "Raleway", "sans serif";
}

:root {
  --fontsize-contactHead: 5vh;
  --fontsize-contactHandle: 3vh;
  --fontsize-nav: 4vh;
  --fontsize-norm: 1.2em;
  --fontsize-code: 0.8em;
  --active-colour: #d7dade;
  --active-colour-dark: #5b5c5b;
  --accent-colour: #9e42f5;
  --accent-colour-dark: #4c2a6e;
  --light-color: #f4f5f9;
}

@media only screen and (max-width: 768px) {
  :root {
    --fontsize-nav: 3.5vh;
    --fontsize-contactHead: 3vh;
  }
}

/* tbh idk why this is here */
* {
  margin: 0;
  padding: 0;
}

.katex {
  font-size: 1em !important;
  font-family: "KaTeX_Main" !important;
}

.codeBlock pre {
  font-size: var(--fontsize-code);
}

.App {
  display: flex;
  width: 100vw;
  min-height: 100vh;
  font-family: "Quicksand", sans-serif;
}
/* the right half of the page */
.coDiv {
  padding: 100px;
  flex: var(--coDiv-flex);
  margin-left: 17vw;
}

/* || NAV BAR */
.navContainer {
  position: fixed;
  height: 100%;
  width: 20vw;
  top: 0;
  left: 0;
  background-color: #f4f5f9;
  z-index: 1000;
}
nav {
  width: 20vw;
}
nav ul li {
  width: 100%;
  height: 10vh;
  text-align: center;
  line-height: 10vh;
}
nav ul li a {
  text-decoration: none;
  font-size: var(--fontsize-nav);
  color: black;
}
nav ul li a:visited {
  text-decoration: none;
}
.navActive {
  background: var(--accent-colour);
  border-right-style: solid;
  border-color: var(--accent-colour-dark);
  border-width: 2vw;
  box-sizing: border-box;
}
.navActive a {
  color: white;
}
/* hover effect courtesy of https://github.com/IanLunn/Hover */
@media only screen and (min-width: 769px) {
  .hvr-sweep-to-right {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }
  .hvr-sweep-to-right:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--active-colour);
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    border-right-style: solid;
    border-color: var(--active-colour-dark);
    border-width: 2vw;
    box-sizing: border-box;
    width: 20vw;
  }
  .hvr-sweep-to-right:hover:before,
  .hvr-sweep-to-right:focus:before,
  .hvr-sweep-to-right:active:before {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
  .hvr-sweep-to-right:hover a,
  .hvr-sweep-to-right:focus a,
  .hvr-sweep-to-right:active a {
    color: black;
  }
}

/* || ABOUT */
.about {
  flex-direction: column;
}
.pfp {
  border-radius: 30%;
  width: auto;
  height: 30vh;
  margin-top: 2vh;
}
.about p,
.postContent p,
.postContent ol {
  margin-bottom: 1em;
  text-indent: 2em;
  font-size: var(--fontsize-norm);
  line-height: 150%;
}
.postContent ol li {
  text-indent: 0px;
}
.postContent p a:visited {
  color: var(--accent-colour);
}

.cv {
  width: 100%;
  text-align: center;
  font-size: var(--fontsize-contactHandle);
}

/* || POSTS */
.posts {
  width: 100%;
  display: inline-block;
  font-size: var(--fontsize-norm);
}
.posts ul {
  list-style-type: none;
}
.posts a {
  text-decoration: none;
  color: black;
}
.posts a:visited {
  color: black;
}
.postPreview {
  position: relative;
  width: 100%;
  height: auto;
  padding: 1vw;
  margin-bottom: 1vh;
  border: 5px solid var(--accent-colour);
}

/* || POST PREVIEWS */
.postPreview:hover,
.postOpen {
  border: 4px solid var(--accent-colour-dark);
  background-color: var(--light-color);
}
.postHead {
  width: 100%;
  border-collapse: collapse;
}
.postTitle {
  text-align: left;
  padding: 0;
  margin: 0;
  width: 80%;
}
.postDate {
  text-align: right;
  padding-right: 0.5vw;
}
.postSummary {
  width: auto;
  padding: 2vh;
}

/* || WRITING */
.post {
  padding-top: 2vh;
  transition-duration: 0.4s;
  padding-bottom: 1vh;
}
.postEndButton {
  height: auto;
  margin-bottom: 2vh;
  padding: 1vh;
  font-size: var(--fontsize-norm);
  color: white;
  background: var(--accent-colour);
  border: none;
}
.hvr-overline-from-right {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
}
.hvr-overline-from-right:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 100%;
  right: 0;
  top: 0;
  background: var(--accent-colour-dark);
  height: 7px;
  -webkit-transition-property: left;
  transition-property: left;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-overline-from-right:hover:before,
.hvr-overline-from-right:focus:before,
.hvr-overline-from-right:active:before {
  left: 0;
}

/* || DATA TABLE */

.data {
  display: flex;
  justify-content: center;
  margin-bottom: 1em;
}

.data table {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
}

.data table thead {
  border-bottom: 2px solid black;
}

.data table th {
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 1vh;
  font-size: 1em;
}

.data table td {
  text-align: center;
  padding-top: 1vh;
  padding-bottom: 1vh;
}

/* || ENVIRONMENTS */

.codeBlock {
  margin-bottom: 1em;
}

.figureContainer {
  margin-top: 1vh;
  margin-bottom: 2vh;
  width: 100%;
}
.figure {
  height: 30vh;
}
.figure img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  position: relative;
}
.caption {
  width: 100%;
  justify-content: center;
  text-align: center;
}
.theorem p,
.proof p {
  text-indent: 0em;
  text-align: left;
}

/* || CONTACT */
.contactHead {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 3vh;
}
.contactMessage {
  text-align: left;
  padding: 0;
  margin: 0;
  font-size: var(--fontsize-contactHead);
}
.contactPhoto {
  text-align: center;
  vertical-align: middle;
  width: auto;
}
.contactPhoto img {
  border-radius: 20%;
  width: 55vh;
  height: auto;
  margin-left: 1vw;
}
.contactInfo {
  width: 100%;
  border-collapse: collapse;
}
.handleIcon {
  width: 11%;
}
.handle a {
  font-size: var(--fontsize-contactHandle);
  text-decoration: none;
  color: black;
}
.handle a:visited {
  color: inherit;
}

/* || GENERAL */

.leftMargin {
  width: 80%;
  margin-left: 10%;
}

.leftMarginWide {
  width: 70%;
  margin-left: 15%;
}

.centredBox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.linkBlack,
.linkPurple {
  text-decoration: none;
}

.linkBlack {
  color: black;
}

.linkPurple {
  color: var(--accent-colour);
  font-weight: bold;
}

/* mobile */

@media only screen and (max-width: 768px) {
  .navContainer {
    position: fixed;
    height: 15vh;
    width: 100vw;
    top: 0;
    background-color: #f4f5f9;
    z-index: 1000;
  }

  .navActive {
    border-right-style: none;
    border-bottom-style: solid;
  }

  .navContainer nav {
    width: 100%;
  }
  .navContainer ul li {
    align-items: center;
    justify-content: center;
    height: 15vh;
    float: left;
    display: inline-flex;
    width: 33%;
  }

  .navContainer ul li a {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5%;
    height: 90%;
    width: 90%;
    line-height: 100%;
  }

  .coDiv {
    padding: 0px;
    width: 94vw;
    flex: var(--coDiv-flex);
    margin-top: 15vh;
    margin-left: 3vw;
    margin-right: 3vw;
  }

  .leftMargin,
  .leftMarginWide {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }

  .posts ul {
    margin-top: 2vh;
  }

  .posts ul li {
    width: 95%;
  }

  .contactPhoto img {
    border-radius: 20%;
    width: auto;
    height: 25vh;
    margin-top: 3vh;
    margin-bottom: 3vh;
  }

  .contactMessage {
    display: flex;
    vertical-align: middle;
    text-align: center;
  }
  .handle {
    padding: 0;
    vertical-align: middle;
  }

  .cv {
    text-align: left;
  }
}
